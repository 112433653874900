
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import { LegController } from "@/app/ui/controllers/LegController";
import { formatDate } from "@/app/infrastructures/misc/Utils";
import router from "@/app/ui/router";

@Options({
  components: {
    Input
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openModalSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      LegController.setOpenModalSave(false);
      next();
    }
  }
})
export default class Edit extends Vue {
  goBack() {
    this.$router.back();
  }

  id() {
    return this.$route.params.id;
  }

  item = {
    status: "Active"
  };

  isDisabled = true;

  // Detail data
  async created() {
    this.fetchDetailLeg();
  }

  fetchDetailLeg() {
    this.getDetailLeg(this.$route.params.id);
  }

  getDetailLeg(id: any) {
    LegController.getLegDetail({ id });
  }

  createdAt = "";
  createdBy = "";
  updatedAt = "";
  updatedBy = "";

  get dataDetail() {
    const detail = LegController.legDetail;
    this.createdAt = formatDate(detail.createdAt);
    this.createdBy = detail.createdName;
    this.updatedAt =
      detail.updatedAt === "0001-01-01T00:00:00Z"
        ? formatDate(detail.createdAt)
        : formatDate(detail.updatedAt);
    this.updatedBy = detail.updatedName;
    return detail;
  }

  // Toggle Route
  openRoute = false;
  onOpenRoute() {
    if (this.isRoute) {
      this.openRoute = true;
    } else {
      this.checkStatusTransport();
    }
  }
  onCloseRoute() {
    this.openRoute = false;
  }
  get isRoute() {
    return LegController.isRoute;
  }
  changeRoute() {
    LegController.setRoute(!this.isRoute);
    this.openRoute = false;
    this.messageSuccess = `Rute Leg ${this.dataDetail.legName} berhasil diubah`;
    this.openSuccess = true;
  }
  cacheRoute() {
    return LegController.cacheRoute;
  }

  // Modal Success
  messageSuccess = "";
  openSuccess = false;

  get openModalSuccess() {
    return LegController.openModalSave;
  }
  onCloseModalSuccess() {
    router.push(`/network/leg-management/${this.$route.params.id}`);
  }

  // Save data
  openSave() {
    return LegController.openModal;
  }
  onOpenSave() {
    LegController.setOpenModal(true);
  }
  onCloseSave() {
    LegController.setOpenModal(false);
  }

  _onEdit() {
    this.onCloseSave();
    this.handleClose();

    const routeValue = this.isRoute ? "active" : "inactive";

    LegController.editLeg({
      legId: Number(this.$route.params.id),
      minSla: Number(this.dataDetail.minSla),
      maxSla: Number(this.dataDetail.maxSla),
      status: this.cacheRoute() === this.isRoute ? "" : routeValue
    });
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  filterInputMax(e: any) {
    const value = e.target.value.replace(/\D+/g, "");
    this.dataDetail.maxSla = value;
  }

  filterInputMin(e: any) {
    const value = e.target.value.replace(/\D+/g, "");
    this.dataDetail.minSla = value;
  }

  validationSlaMin() {
    if (this.dataDetail.minSla === "0") {
      return true;
    }
    return false;
  }

  validationEmptyMin() {
    if (this.dataDetail.minSla === "") {
      return true;
    }
    return false;
  }

  validationEmptyMax() {
    if (this.dataDetail.maxSla === "") {
      return true;
    }
    return false;
  }

  validationSlaMax() {
    if (
      Number(this.dataDetail.maxSla) < Number(this.dataDetail.minSla) &&
      this.dataDetail.maxSla !== ""
    ) {
      return true;
    }
    return false;
  }

  // error transport status
  checkStatusTransport() {
    LegController.getTransportDetail({ id: this.dataDetail.transportId });
  }

  get isErrorTransport() {
    return LegController.isErrorTransport;
  }

  closeErrorTransport() {
    LegController.setErrorTransport(false);
  }

  //Error
  get isError() {
    return LegController.isError;
  }

  get isErrorEdit() {
    return LegController.isErrorEdit;
  }

  //Error Cause
  get errorCause() {
    return LegController.isErrorCause;
  }

  handleClose() {
    LegController.setErrorEdit(false);
  }

  // Loading
  get isLoading() {
    return LegController.isLoadingDetail;
  }
}
