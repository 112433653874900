
import { Options, Vue } from "vue-class-component";
import Table from "@/app/ui/components/table/index.vue";
import router from "@/app/ui/router";
import { LegController } from "@/app/ui/controllers/LegController";
import debounce from "lodash/debounce";
import {
  checkRolePermission,
  ellipsisString
} from "@/app/infrastructures/misc/Utils";
import { ROUTE_LEG } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    Table
  }
})
export default class LegManagement extends Vue {
  // Breadcrumb
  breadcrumb: Array<string> = [];

  // Search
  search = "";
  public onSearch(value: string) {
    if (value.length >= 3 || !value) {
      LegController.searchAction();
      this.search = value;
      this.firstPage();
    }
  }

  get getCurrentRoute() {
    return this.$route.meta.title;
  }
  get isShowTable() {
    return this.dataLeg.length !== 0 && !this.isLoading && !this.isError;
  }
  get isDetailAble() {
    return checkRolePermission(ROUTE_LEG.DETAIL);
  }

  public clearSearch() {
    LegController.clear();
    this.search = "";
    this.firstPage();
  }

  // Filter
  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" }
  ];
  statusSelect = false;
  statusName = "";

  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    this.onCloseStatusSelect();
    LegController.selectStatusAction(value);
    this.statusName = name;
    this.firstPage();
  }

  created() {
    this.getListLeg();
    this.firstPage();
    LegController.setStatus("");
  }

  // get data
  getListLeg() {
    return LegController.getLegList({
      status: "",
      page: 1,
      limit: 10,
      originId: "",
      destinationId: "",
      sortBy: "asc"
    });
  }

  firstPage() {
    return LegController.setFirstPage();
  }

  get dataLeg() {
    return LegController.legData.data;
  }

  columns = [
    {
      name: "ID Leg",
      key: "id",
      styleHead: "w-1/12 text-center whitespace-no-wrap",
      styleBody: "text-red-lp-200",
      render: (item: any) => {
        return `<div class="flex justify-center"><span class='text-center text-red-lp-200'>${item.id}</span></div>`;
      }
    },
    {
      name: "Nama Leg",
      key: "legName",
      styleHead: "w-4/12 text-left",
      styleBody: "text-left rounded px-2 py-0",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${ellipsisString(
          item.legName,
          120
        )}</div>`;
      }
    },
    {
      name: "Tipe Leg",
      key: "slaMin",
      styleHead: "w-1/12 text-left",
      styleBody: "",
      render: (item: any) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      <span class="whitespace-no-wrap">
                        ${item.legType}
                      </span>
                  </div>
                </div>`;
      }
    },
    {
      name: "Transport Moda",
      key: "transport",
      styleHead: "w-1/12 text-left whitespace-no-wrap",
      styleBody: "text-left rounded py-0",
      render: (item: any) => {
        return `<div class="flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                    <div class='text-black-lp-300'>${item.transport}</div>
                  </div>
                </div>`;
      }
    },
    {
      name: "Vendor",
      key: "vendor",
      styleHead: "w-1/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<span class="whitespace-no-wrap text-black-lp-300">
              ${item.vendorName}</span>`;
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-1/12 text-left",
      styleBody: "",
      render: (item: any) => {
        return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${
          item.status.toLowerCase() === "active"
            ? "bg-green-lp-300"
            : "bg-red-300"
        } ${
          item.status.toLowerCase() === "active"
            ? "text-green-lp-200"
            : "text-red-lp-200"
        }"
      ><span class="capitalize">${item.status.toLowerCase()}</span></div></div>`;
      }
    }
  ];

  // Loading
  get isLoading() {
    return LegController.isLoading;
  }

  //Error
  get isError() {
    return LegController.isError;
  }

  //Error Cause
  get errorCause() {
    return LegController.isErrorCause;
  }

  // To Detail
  onClickRow(item: any) {
    router.push(`/network/leg-management/${item.id}`);
  }

  // Pagination
  get page() {
    return LegController.page;
  }
  get limit() {
    return LegController.limit;
  }
  nextPage() {
    LegController.setNextPage();
  }

  prevPage() {
    LegController.setPrevPage();
  }

  toPage = debounce(value => {
    LegController.setPageAction(value);
  }, 500);

  get locationSearchOriginList() {
    return LegController.locationSearchOriginList?.locationSearchData;
  }

  get locationSearchDestinationList() {
    return LegController.locationSearchDestinationList?.locationSearchData;
  }

  get locationSearchOriginNotFound() {
    return LegController.locationSearchOriginNotFound;
  }

  get locationSearchDestinationNotFound() {
    return LegController.locationSearchDestinationNotFound;
  }

  // select-search-origin
  selectSearchOriginValue = "";
  statusSelectSearchOrigin = false;
  onSelectSearchOrigin(value: string) {
    this.selectSearchOriginValue = value;
    LegController.getLocationSearchList({
      type: "origin",
      page: 1,
      limit: 10,
      search: value
    });
  }
  onOpenSelectSearchOrigin() {
    this.statusSelectSearchOrigin = true;
    const value = this.selectSearchOriginValue;
    value.length === 0
      ? LegController.getLocationSearchList({
          type: "origin",
          page: 1,
          limit: 10,
          search: "jaka"
        })
      : LegController.getLocationSearchList({
          type: "origin",
          page: 1,
          limit: 10,
          search: value
        });
  }
  onCloseSelectSearchOrigin() {
    this.statusSelectSearchOrigin = false;
  }
  onChooseSelectSearchOrigin(name: string, code: string) {
    this.selectSearchOriginValue = name;
    this.statusSelectSearchOrigin = false;
    LegController.selectSearchOriginAction(code);
  }

  // select-search destination
  selectSearchDestinationValue = "";
  statusSelectSearchDestination = false;
  onSelectSearchDestination(value: string) {
    this.selectSearchDestinationValue = value;
    LegController.getLocationSearchList({
      type: "destination",
      page: 1,
      limit: 10,
      search: value
    });
  }
  onOpenSelectSearchDestination() {
    this.statusSelectSearchDestination = true;
    const value = this.selectSearchDestinationValue;
    value.length === 0
      ? LegController.getLocationSearchList({
          type: "destination",
          page: 1,
          limit: 10,
          search: "jaka"
        })
      : LegController.getLocationSearchList({
          type: "destination",
          page: 1,
          limit: 10,
          search: value
        });
  }
  onCloseSelectSearchDestination() {
    this.statusSelectSearchDestination = false;
  }
  onChooseSelectSearchDestination(name: string, code: string) {
    this.statusSelectSearchDestination = false;
    this.selectSearchDestinationValue = name;
    LegController.selectSearchDestinationAction(code);
  }
}
