/* eslint-disable @typescript-eslint/camelcase */
import { LegEntities, LegData, TransportData } from "@/domain/entities/Leg";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { LegPresenter } from "../presenters/LegPresenter";
import { EditLegApiRequest } from "@/data/payload/api/LegApiRequest";
import { LocationSearchPresenter } from "../presenters/LocationSearchPresenter";
import { LocationSearchEntities } from "@/domain/entities/LocationSearch";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface LegState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  locationSearchOriginList: LocationSearchEntities;
  locationSearchDestinationList: LocationSearchEntities;
  legData: LegEntities;
  legDetail: LegData;
  transportStatus: TransportData;
  isError: boolean;
  isErrorEdit: boolean;
  isRoute: boolean;
  isErrorCause: string;
  openModal: boolean;
  openModalSave: boolean;
  cacheRoute: boolean;
  isErrorTransport: boolean;
  selectSearchOrigin: string;
  selectSearchDestination: string;
  locationSearchOriginNotFound: boolean;
  locationSearchDestinationNotFound: boolean;
}
@Module({ namespaced: true, dynamic: true, store, name: "leg" })
class LegStore extends VuexModule implements LegState {
  public isLoading = false;
  public isLoadingDetail = false;
  public locationSearchOriginList = new LocationSearchEntities(
    new Pagination(1, 10),
    []
  );
  public locationSearchDestinationList = new LocationSearchEntities(
    new Pagination(1, 10),
    []
  );
  public legData = new LegEntities(new Pagination(1, 10), []);
  public legDetail = new LegData();
  public transportStatus = new TransportData("");
  public search = "";
  public status = "";
  public page = 1;
  public limit = 10;
  public isError = false;
  public isErrorEdit = false;
  public isRoute = false;
  public isErrorCause = "";
  public openModal = false;
  public openModalSave = false;
  public cacheRoute = false;
  public isErrorTransport = false;
  public selectSearchOrigin = "";
  public selectSearchDestination = "";
  public locationSearchOriginNotFound = false;
  public locationSearchDestinationNotFound = false;

  @Action
  public getLocationSearchList(params: {
    type: string;
    page: number;
    limit: number;
    search: string;
  }) {
    const presenter = container.resolve(LocationSearchPresenter);
    if (params.search.length > 2) {
      presenter
        .getLocationSearchList(params.page, params.limit, params.search)
        .then((res: LocationSearchEntities) => {
          if (params.type === "origin") {
            this.setLocationSearchOriginList(res);
            if (res.locationSearchData.length > 0) {
              this.setLocationSearchOriginNotFound(false);
            } else {
              this.setLocationSearchOriginNotFound(true);
            }
          } else {
            this.setLocationSearchDestinationList(res);
            if (res.locationSearchData.length > 0) {
              this.setLocationSearchDestinationNotFound(false);
            } else {
              this.setLocationSearchDestinationNotFound(true);
            }
          }
        });
    } else if (params.search.length === 0) {
      presenter
        .getLocationSearchList(params.page, params.limit, "jakarta")
        .then((res: LocationSearchEntities) => {
          if (params.type === "origin") {
            this.setLocationSearchOriginList(res);
          } else {
            this.setLocationSearchDestinationList(res);
          }
        });
      if (params.type === "origin") {
        this.selectSearchOriginAction("");
      } else {
        this.selectSearchDestinationAction("");
      }
    } else {
      presenter
        .getLocationSearchList(params.page, params.limit, "jakarta")
        .then((res: LocationSearchEntities) => {
          if (params.type === "origin") {
            this.setLocationSearchOriginList(res);
          } else {
            this.setLocationSearchDestinationList(res);
          }
        });
    }
  }

  @Action
  public getLegList(params: {
    page: number;
    limit: number;
    status: string;
    originId: string;
    destinationId: string;
    sortBy: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(LegPresenter);
    presenter
      .getLegList(
        params.page,
        params.limit,
        params.status,
        params.originId,
        params.destinationId,
        params.sortBy
      )
      .then((res: LegEntities) => {
        this.setLegList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getLegDetail(params: { id: any }) {
    this.setLoadingDetail(true);
    const presenter = container.resolve(LegPresenter);
    presenter
      .getLegDetail(params.id)
      .then((res: LegData) => {
        this.setLegDetail(res);
        this.setError(false);
        this.setErrorEdit(false);
        this.setErrorCause("");
        this.setRoute(res.status.toLowerCase() === "active" ? true : false);
        this.setCacheRoute(
          res.status.toLowerCase() === "active" ? true : false
        );
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingDetail(false);
      });
  }

  @Action
  public editLeg(params: {
    legId: number;
    minSla: number;
    maxSla: number;
    status: string;
  }) {
    this.setLoadingDetail(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(LegPresenter);
    presenter
      .editLeg(
        new EditLegApiRequest(
          params.legId,
          params.minSla,
          params.maxSla,
          params.status
        )
      )
      .then(() => {
        this.setOpenModalSave(true);
        this.setErrorEdit(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Leg Management Gagal!", () =>
            this.editLeg(params)
          )
        );
      })
      .finally(() => {
        this.setLoadingDetail(false);
        this.setOpenModal(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public getTransportDetail(params: { id: number }) {
    this.setLoadingDetail(true);
    const presenter = container.resolve(LegPresenter);
    presenter
      .getTransportDetail(params.id)
      .then((res: TransportData) => {
        this.setTransportDetail(res);
        if (res.status.toLowerCase() === "inactive") {
          this.setErrorTransport(true);
        } else {
          this.setRoute(true);
        }
      })
      .catch(() => {
        this.setError(true);
        this.setErrorTransport(true);
      })
      .finally(() => {
        this.setLoadingDetail(false);
      });
  }

  @Action
  public async setNextPage() {
    await this.nextPage();
    this.getLegList({
      status: this.status,
      page: this.page,
      limit: 10,
      originId: this.selectSearchOrigin,
      destinationId: this.selectSearchDestination,
      sortBy: "asc"
    });
  }

  @Action
  public async setPrevPage() {
    if (this.page !== 1) {
      await this.prevPage();
      this.getLegList({
        status: this.status,
        page: this.page,
        limit: 10,
        originId: this.selectSearchOrigin,
        destinationId: this.selectSearchDestination,
        sortBy: "asc"
      });
    }
  }

  @Action
  public setFirstPage() {
    this.setFirst();
  }

  @Action
  public setPageAction(value: number) {
    this.setPage(value);
    this.getLegList({
      status: this.status,
      page: value,
      limit: 10,
      originId: this.selectSearchOrigin,
      destinationId: this.selectSearchDestination,
      sortBy: "asc"
    });
  }

  @Action
  public selectStatusAction(value: string) {
    this.setStatus(value);
    this.getLegList({
      status: this.status,
      page: 1,
      limit: 10,
      originId: this.selectSearchOrigin,
      destinationId: this.selectSearchDestination,
      sortBy: "asc"
    });
  }

  @Action
  public searchAction() {
    this.setSearch(
      `${this.selectSearchOrigin} - ${this.selectSearchDestination}`
    );
    this.getLegList({
      status: this.status,
      page: 1,
      limit: 10,
      originId: this.selectSearchOrigin,
      destinationId: this.selectSearchDestination,
      sortBy: "asc"
    });
  }

  @Action
  public clear() {
    this.searchAction();
    this.getLegList({
      status: this.status,
      page: 1,
      limit: 10,
      originId: "",
      destinationId: "",
      sortBy: "asc"
    });
  }

  @Action
  public selectSearchOriginAction(value: string) {
    this.setSelectSearchOrigin(value);
    this.searchAction();
  }

  @Action
  public selectSearchDestinationAction(value: string) {
    this.setSelectSearchDestination(value);
    this.searchAction();
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setLoadingDetail(isLoading: boolean) {
    this.isLoadingDetail = isLoading;
  }

  @Mutation
  private setLocationSearchOriginList(data: LocationSearchEntities) {
    this.locationSearchOriginList = data;
  }

  @Mutation
  private setLocationSearchDestinationList(data: LocationSearchEntities) {
    this.locationSearchDestinationList = data;
  }

  @Mutation
  private setLegList(data: LegEntities) {
    this.legData = data;
  }

  @Mutation
  private setLegDetail(data: LegData) {
    this.legDetail = data;
  }

  @Mutation
  private setTransportDetail(data: TransportData) {
    this.transportStatus = data;
  }

  @Mutation
  public setRoute(boolean: boolean) {
    this.isRoute = boolean;
  }

  @Mutation
  public setCacheRoute(boolean: boolean) {
    this.cacheRoute = boolean;
  }

  @Mutation
  private async setPage(page: number) {
    this.page = page;
  }

  @Mutation
  private async nextPage() {
    if (this.page >= 1) {
      this.page = Number(this.page) + 1;
    }
  }

  @Mutation
  private async prevPage() {
    this.page = Number(this.page) - 1;
  }

  @Mutation
  private async setFirst() {
    this.page = 1;
  }

  @Mutation
  private async setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  public async setStatus(value: string) {
    this.status = value;
  }

  @Mutation
  public setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  public setErrorEdit(boolean: boolean) {
    this.isErrorEdit = boolean;
  }

  @Mutation
  private setErrorCause(str: string) {
    this.isErrorCause = str;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenModalSave(value: boolean) {
    this.openModalSave = value;
  }

  @Mutation
  public setErrorTransport(value: boolean) {
    this.isErrorTransport = value;
  }

  @Mutation
  public setSelectSearchOrigin(value: string) {
    this.selectSearchOrigin = value;
  }

  @Mutation
  public setSelectSearchDestination(value: string) {
    this.selectSearchDestination = value;
  }
  @Mutation
  public setLocationSearchOriginNotFound(value: boolean) {
    this.locationSearchOriginNotFound = value;
  }
  @Mutation
  public setLocationSearchDestinationNotFound(value: boolean) {
    this.locationSearchDestinationNotFound = value;
  }
}

export const LegController = getModule(LegStore);
