
import { Options, Vue } from "vue-class-component";

import { LegController } from "@/app/ui/controllers/LegController";
import {
  checkRolePermission,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { ROUTE_LEG } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {}
})
export default class DetailLeg extends Vue {
  async created() {
    this.fetchDetailLeg();
  }

  fetchDetailLeg() {
    this.getDetailLeg(this.$route.params.id);
  }

  getDetailLeg(id: any) {
    LegController.getLegDetail({ id });
  }

  get id() {
    return this.$route.params.id;
  }
  get isEditAble() {
    return checkRolePermission(ROUTE_LEG.EDIT);
  }

  createdAt = "";
  createdBy = "";
  updatedAt = "";
  updatedBy = "";

  get dataDetail() {
    const detail = LegController.legDetail;
    this.createdAt = formatDate(detail.createdAt);
    this.createdBy = detail.createdName;
    this.updatedAt =
      detail.updatedAt === "0001-01-01T00:00:00Z"
        ? formatDate(detail.createdAt)
        : formatDate(detail.updatedAt);
    this.updatedBy = detail.updatedName;
    return detail;
  }

  goBack() {
    this.$router.push(`/network/leg-management`);
  }

  item = {
    status: "Inactive"
  };

  // Loading
  get isLoading() {
    return LegController.isLoadingDetail;
  }

  goEdit(id: string) {
    this.$router.push(`/network/leg-management/${id}/edit`);
  }

  //Error
  get isError() {
    return LegController.isError;
  }

  //Error Cause
  get errorCause() {
    return LegController.isErrorCause;
  }
}
